import React from "react";
import { Container, } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import backgroundImage from "../../../../assets/images/backgroundImage.png";
import heroImage from "../../../../assets/images/heroImage.png";
import LockerMenu from "../../LockerMenu";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100dvh;
  width: 100%;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;
  position: relative;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh - 70px);
  width: 100%;
  padding: 0 50px !important;
  z-index: 1;
  display: flex;
  align-items: center;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const HeroSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="home">
        <LockerMenu />
        <InnerContinerWraper>
          <CustomGrid container p="40px 0" ai="start" jc="start">
            <CustomGrid
              container
              item
              xs={12}
              sm={9}
              md={6}
              ai="start"
              fd="column"
              g="20px"
              p="10% 0 0 0"
            >
              <SectionHeading>Baby Hemule</SectionHeading>
              <Paragraph
              sx={{ fontSize: { xs: "22px", sm: "26px" } }}
                p="10px 0"
                data-aos="zoom-in"
              >
                Baby Hemule is the adored house cat of Vitalik Buterin!
              </Paragraph>
              <div
                // item
                // xs={12}
                data-aos="fade-up"
                style={{
                  padding:"10px",
                  borderRadius:"10px",
                  background: "linear-gradient(to right,#4F74F6,#FA66C6)",
                }}
              >
                <Paragraph
                  ta="left"
                  data-aos="zoom-in"
                >
                  Contract Address: TBA
                </Paragraph>
              </div>
            </CustomGrid>
            <CustomGrid item xs={12} 
              sm={9}
              md={6}
              ai="start">
              <DataImage src={heroImage}/>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default HeroSection;
