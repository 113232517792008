import React, { useState } from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import ImagesSec from "../../../../assets/images/ImagesSection.png";
const OuterContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 120vh;
  width: 100%;
  background-color: #fff;
  display: grid;
  place-items: center;
  padding: 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background-color: #000;
  padding: 0 !important;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;
const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  padding: 0 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "50%")};
  left: ${(props) => (props.l ? props.l : "50%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(-50%,-50%)")};
  max-width: ${(props) => (props.mw ? props.mw : "48%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 999px) {
    max-width: ${(props) => props.mw999? props.mw999 : "60%"};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "100%")};
    top: ${(props) => (props.t599 ? props.t599 : props.t599)};
    left: ${(props) => (props.l599 ? props.l599 : props.l599)};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
  }
`;
const ImagesSection = () => {
  const [showToast, setShowToast] = useState(false);
  const addressToCopy = "00000000000000Xx0000x0000xxxx0";

  const handleCopy = () => {
    navigator.clipboard.writeText(addressToCopy);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };
  return (
    <>
      <OuterContinerWraper maxWidth="xxl"  id="ImagesSection">
        <ContinerWraper maxWidth="xxl">
          <InnerContinerWraper>
            <AbsoluiteImage src={ImagesSec} />
          </InnerContinerWraper>
        </ContinerWraper>
      </OuterContinerWraper>
    </>
  );
};

export default ImagesSection;
