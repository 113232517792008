import styled from "@emotion/styled";
import { Container } from "@mui/material";
import React from "react";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import footerImage from "../../../../assets/images/footerImage.png";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import { Link } from "@mui/material";
const ContinerWraper = styled(Container)`
  background: #ffffff;
  position: relative;
  height: auto;
  width: 100%;
  padding: 100px 0 0 !important;
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: ${(props) => (props.t ? props.t : "0%")};
  right: ${(props) => (props.l ? props.l : "30px")};
  /* transform: ${(props) =>
    props.tran ? props.tran : "translate(-10,0%)"}; */
  max-width: ${(props) => (props.mw ? props.mw : "25%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 999px) {
    max-width: ${(props) => (props.mw999 ? props.mw999 : "30%")};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "30%")};
    bottom: ${(props) => (props.t599 ? props.t599 : props.t599)};
    right: ${(props) => (props.l599 ? props.l599 : props.l599)};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
  }
`;
const Footer = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl">
        <Container>
          <CustomGrid container>
            <CustomGrid
              container
              item
              xs={12}
              p="40px"
              jc="start"
              data-aos="fade-up"
              sx={{
                borderRadius: "10px",
                background: "linear-gradient(to right,#4F74F6,#FA66C6)",
              }}
            >
              <CustomGrid
                item
                xs={12}
                sm={9}
                p="10px 10px"
                sx={{ position: "relative" }}
              >
                <SectionHeading fs="60px">
                  Start your adventure with Baby Hemule
                </SectionHeading>
              </CustomGrid>
              <CustomGrid
                item
                xs={12}
                sm={7}
                g="10px"
                p="10px 10px"
                jc="start"
                sx={{ position: "relative" }}
              >
                <Link
                  target="blank"
                  sx={{ textDecoration: "none" }}
                  href="https://twitter.com/BHemuleCoin"
                >
                  <DataImage mw="40px" src={x} />
                </Link>

                <Link
                  target="blank"
                  sx={{ textDecoration: "none" }}
                  href="#"
                >
                  <DataImage mw="40px" src={tg} />
                </Link>
              </CustomGrid>
              <AbsoluiteImage src={footerImage} />
            </CustomGrid>
            <CustomGrid
              container
              item
              xs={12}
              jc="space-between"
              p="40px 0"
              g="20px 0"
              sx={{ borderBottom: "1px solid #00000080" }}
            >
              <CustomGrid
                item
                xs={12}
                sm={6}
                md={2}
                fd="column"
                ai="start"
                g="10px"
              >
                <SectionHeading
                  fs="26px"
                  fs1175="26px"
                  fs1024="24px"
                  fs599="22px"
                  c="#000"
                >
                  Baby Hemule
                </SectionHeading>
                <Paragraph color="#00000080">
                  Baby Hemule is the adored house cat of Vitalik Buterin!
                </Paragraph>
                <Paragraph color="#00000080">
                  Email: info@babyhemule.com
                </Paragraph>
              </CustomGrid>
              <CustomGrid
                container
                item
                xs={12}
                sm={6}
                md={4}
                ai="start"
                g="20px 0"
              >
                <CustomGrid item xs={12} sm={6} fd="column" ai="start" g="10px">
                  <SectionHeading
                    fs="26px"
                    fs1175="26px"
                    fs1024="24px"
                    fs599="22px"
                    c="#000"
                    ta="center"
                  >
                    Quick links
                  </SectionHeading>
                  <Link
                    target="blank"
                    sx={{ textDecoration: "none" }}
                    href="#"
                  >
                    <Paragraph color="#00000080" textAlign="center">
                      Etherscan
                    </Paragraph>
                  </Link>
                  <Link
                    target="blank"
                    sx={{ textDecoration: "none" }}
                    href="#"
                  >
                    <Paragraph color="#00000080" textAlign="center">
                      Uniswap
                    </Paragraph>
                  </Link>
                  <Link
                    target="blank"
                    sx={{ textDecoration: "none" }}
                    href=""
                  >
                    <Paragraph color="#00000080" textAlign="center">
                      Dextools
                    </Paragraph>
                  </Link>
                </CustomGrid>
                <CustomGrid item xs={12} sm={6} fd="column" ai="start" g="10px">
                  <SectionHeading
                    fs="26px"
                    fs1175="26px"
                    fs1024="24px"
                    fs599="22px"
                    c="#000"
                    ta="center"
                  >
                    Social
                  </SectionHeading>
                  <Link
                    target="blank"
                    sx={{ textDecoration: "none" }}
                    href="#"
                  >
                    <Paragraph color="#00000080" textAlign="center">
                      Telegram
                    </Paragraph>
                  </Link>
                  <Link
                    target="blank"
                    sx={{ textDecoration: "none" }}
                    href="https://twitter.com/BHemuleCoin"
                  >
                    <Paragraph color="#00000080" textAlign="center">
                      x
                    </Paragraph>
                  </Link>
                </CustomGrid>
              </CustomGrid>
            </CustomGrid>
            <CustomGrid item xs={12} p="20px 0" g="10px">
              <Paragraph sx={{ wordBreak: "break-all" }} color="#00000080">
                Contract: TBA
              </Paragraph>
            </CustomGrid>
          </CustomGrid>
        </Container>
      </ContinerWraper>
    </>
  );
};

export default Footer;
