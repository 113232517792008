import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@mui/material'

const CustomBtn = styled(Button)`
    background: ${props => props.bgc ? props.bgc : "transparent"};
    font-size: ${props=> props.fs ? props.fs : "16px"};
    border-radius: 10px;
    min-width:  ${props=> props.w ? props.w: "150px"};;
    font-family: ${props=> props.fm ? props.fm : "Pulang"};
    margin:  ${props=> props.m ? props.m : "0"};
    padding:  ${props=> props.p ? props.p : "4px 20px"};
    color:${props => props.c ? props.c : "#fff"};
    border: ${props => props.b ? props.b : "1px solid #fff"};
    transition: 1s;
    text-transform: none;
    cursor: ${props => props.cur ? props.cur : "pointer"};
    z-index: 1;
    :hover{
      color: ${props => props.hc ? props.hc : "#fff"};
      background: ${props => props.hbgc ? props.hbgc : "transparent"};
      
    }
    @media(max-width:599px){
      padding:  ${props=> props.p ? props.p : "4px 20px"};
      font-size: ${props=> props.fs599 ? props.fs599 : "12px"};
  }

` 

const CustomButton = (props) => {
  const {children} = props
  return (
    <CustomBtn variant='filled' {...props}>
        {children}
    </CustomBtn>
  )
}

export default CustomButton