import React from "react";
import logo from "../../../../assets/images/logo.png";
import { DesktopMainMenu, MenuLink } from "../styles";
import { DataImage } from "../../../Styles/style";
import CustomButton from "../../CustomBtn";
const DesktopMenu = (props) => {
  return (
    <DesktopMainMenu maxWidth="xl">
      <MenuLink href="/" p="0px">
        <DataImage src={logo} mw="50px" alt="Logo" />
      </MenuLink>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.menuList.map((value, i) => (
          <MenuLink
            key={i}
            href={value.link}
            target={value.target}
            className={value.customClass}
          >
            {value.title}
          </MenuLink>
        ))}
        <MenuLink p="0" href="#" target="blank">
          <CustomButton>
          Buy Now
          </CustomButton>
        </MenuLink>
      </div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
