import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import aboutImage from "../../../../assets/images/aboutImage.png";
import Paragraph from "../../Paragraph";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
  padding: 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  display: grid;
  place-items: center;
  padding: 20px 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="about">
        <InnerContinerWraper>
          <CustomGrid container p="40px 0" ai="start" jc="start" g="30px 0">
            <CustomGrid item xs={12} sm={9} md={4} ai="center" jc="center" p="10% 0 0 0" sx={{}}>
              <DataImage mw="80%" src={aboutImage} />
            </CustomGrid>
            <CustomGrid
              container
              item
              xs={12}
              sm={9}
              md={8}
              ai="start"
              fd="column"
              g="20px"
            >
              <SectionHeading c="#000">About</SectionHeading>
              <Paragraph
                sx={{ fontSize: { xs: "22px", sm: "26px" } }}
                p="10px 0"
                color="#000"
                data-aos="zoom-in"
              >
                Baby Hemule, is an ERC-20 token inspired by Ethereum's founder
                Vitalik Buterin and his beloved cat of Hemule that is the
                purrfect addition to your digital purr-tfolio. This little
                kitten is destined to follow the footsteps of his mother,
                Hemule's, legendary footsteps to open doors to all big and small
                investors on the blockchain, no matter how big or small their
                paws 🙂 <br />
                <br />
                To honor Vitalik, Baby Hemule will stay loyal to his defi
                principles by ensuring true decentralizion and community
                dedication to it's holders. This token will be truly stealth
                launched with no controlling entities. no team tokens, and a
                contract devoid of taxes, blacklisting, or malicious functions.{" "}
                <br />
                <br />
                Let Baby Hemule be your digital companion, to guide you through
                the ever-evolving world of defi! <br />
              </Paragraph>
              
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default AboutSection;
